<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                  label="Dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="dátum"
                    rules="required"
                    vid="date"
                >
                  <b-input-group>
                    <b-form-input
                        v-model="formData.date"
                        type="text"
                        placeholder="ÉÉÉÉ-HH-NN"
                        autocomplete="off"
                        :formatter="formatDateAllowedSymbols"
                        show-decade-nav
                        required
                    />

                    <b-input-group-append>
                      <b-form-datepicker
                          v-model="formData.date"
                          size="sm"
                          show-decade-nav
                          button-only
                          right
                          :locale="$i18n.locale"
                          v-bind="labels[$i18n.locale] || {}"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Típus"
              >
                <validation-provider
                    #default="{ errors }"
                    name="típus"
                    rules="required"
                    vid="type"
                >
                  <v-select
                      v-model="formData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="typeOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTimepicker,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import tabs from '@/views/pages/_components/_tabs/_reservationSpecialDay/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import labels from '@/libs/datepicker/labels'
import {formatDateAllowedSymbols} from "@core/utils/filter";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTimepicker,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      formData: {
        id: '',
        date: '',
        type: '',
      },
      typeOptions: [],
      labels,
      formatDateAllowedSymbols,
      required,
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservationSpecialDay', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {

        this.formData.id = this.$route.params.id
        this.formData.date = entity.date
        this.$store.dispatch('reservationSpecialDayTypes').then(tResponse => {
          this.typeOptions = tResponse.data.values

          if (this.typeOptions.length) {
            Object.keys(this.typeOptions).forEach(key => {
              if (this.typeOptions[key].value === entity.type) {
                this.formData.type = this.typeOptions[key]
              }
            })
          }
        })
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.type = this.formData.type.value

          this.$store.dispatch('updateReservationSpecialDay', fd).then(async response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-special-days'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
